import { createStore } from 'redux';
import rootReducer from './__reducers';

// ---------------------
import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
const loggerMiddleware = createLogger();

let store = null
if (process.env.NODE_ENV === 'development') {
    store = createStore(
        rootReducer,
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    );
} else {
    store = createStore(rootReducer);
}

export default store